.md-menu {
  margin-right: 15px;
  cursor: pointer;
}
.md-menu:hover {
  background-color: #dae9f7;
}
.row-width {
  width: 98.5%;
}
.el-card__header {
  vertical-align: middle;
}
